import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Can from "../components/Can";

import Home from './Home';
import ReqLab from './reqLab/ReqLab';
import PubLab from './pubLab/PubLab';
import RegisterLab from './pubLab/RegisterLab';
import Sim4student from './sim4student/Sim4student';
import Voucherstudent from './voucherstudent/Voucherstudent';
import ApplyVoucher from './voucherstudent/ApplyVoucher';
import ApplySim from './sim4student/ApplySim';
import ApplySimHome from './sim4student/ApplySimHome';
import Popsostudent from './popsostudent/Popsostudent';
import Trenitaliastudent from './trenitaliastudent/Trenitaliastudent';
import PubLabDettPage from './pubLab/PubLabDettPage';
import TimConsolle from './timConsolle/TimConsolle';
import TimInventory from './timInventory/TimInventory';
import VoucherConsolle from './voucherConsolle/VoucherConsolle';


function BootstrapNavbar(props) {
    var loginButton;
    var areaStudente;
    var areaTim;
    var itemTimConsolle;
    var itemTimInventory;
    var itemVoucherConsolle;
    var itemTim;
    var itemTimHome;
    var itemVoucher;
    var itemPopso;
    var itemTrenitaliastudent;


    if (props.apiAuthResponse) {
        loginButton = <Nav.Link href={process.env.REACT_APP_URL_AUTH_LOGOUT}>Logout</Nav.Link>;
    } else {
        loginButton = <Nav.Link href={process.env.REACT_APP_URL_AUTH_LOGIN}>Login</Nav.Link>;
    }

    if (props.apiAuthGroups) {

        areaStudente = <Can
            role={props.apiAuthGroups}
            perform="studentArea:visible"
            yes={() => (
                <NavDropdown title="Area studente" id="basic-nav-dropdown">
                    {itemTim}
                    {itemTimHome}
                    {itemVoucher}
                    {itemPopso}
                    {itemTrenitaliastudent}
                </NavDropdown>
            )}
            no={() => null}
        />

        areaTim = <Can
            role={props.apiAuthGroups}
            perform="adminArea:visible"
            yes={() => (
                <NavDropdown title="Amministrazione" id="basic-nav-dropdown">
                    {itemTimConsolle}
                    {itemTimInventory}
                    {itemVoucherConsolle}
                </NavDropdown>
            )}
            no={() => null}
        />

        itemTimConsolle = <Can
            role={props.apiAuthGroups}
            perform="timConsolle-page:visit"
            yes={() => (
                <NavDropdown.Item as={Link} to="/timConsolle">Consolle Customer Care TIM</NavDropdown.Item>
            )}
            no={() => null}
        />

        itemTimInventory = <Can
            role={props.apiAuthGroups}
            perform="timConsolle-page:visit"
            yes={() => (
                <NavDropdown.Item as={Link} to="/timInventory">Inventario TIM</NavDropdown.Item>
            )}
            no={() => null}
        />

        itemVoucherConsolle = <Can
            role={props.apiAuthGroups}
            perform="voucherConsolle-page:visit"
            yes={() => (
                <NavDropdown.Item as={Link} to="/voucherConsolle">Consolle Voucher</NavDropdown.Item>
            )}
            no={() => null}
        />

        itemTim = <Can
            role={props.apiAuthGroups}
            perform="tim4student-req-page:visit"
            yes={() => (
                <NavDropdown.Item as={Link} to="/Sim4student">Richiesta SIM dati e Router</NavDropdown.Item>
            )}
            no={() => null}
        />

        itemTimHome = <Can
            role={props.apiAuthGroups}
            perform="tim4student-page:visit"
            yes={() => (
                <NavDropdown.Item as={Link} to="/ApplySimHome">Gestione SIM dati e Router</NavDropdown.Item>
            )}
            no={() => null}
        />

        itemVoucher = <Can
            role={props.apiAuthGroups}
            perform="voucherstudent-page:visit"
            yes={() => (
                <React.Fragment>
                    <NavDropdown.Divider />
                    <NavDropdown.Item as={Link} to="/Voucherstudent">Richiesta Voucher</NavDropdown.Item>
                </React.Fragment>
            )}
            no={() => null}
        />

        itemPopso = <Can
            role={props.apiAuthGroups}
            perform="popsostudent-page:visit"
            yes={() => (
                <React.Fragment>
                    <NavDropdown.Divider />
                    <NavDropdown.Item as={Link} to="/Popsostudent">Conferma ricezione Badge Ateneo+</NavDropdown.Item>
                </React.Fragment>
            )}
            no={() => null}
        />

        itemTrenitaliastudent = <Can
            role={props.apiAuthGroups}
            perform="trenitaliastudent-page:visit"
            yes={() => (
                <React.Fragment>
                    <NavDropdown.Divider />
                    <NavDropdown.Item as={Link} to="/Trenitaliastudent">Trenitalia Welfare Plus 10%</NavDropdown.Item>
                </React.Fragment>
            )}
            no={() => null}
        />

    }

    return (
        <React.Fragment>
            <Router>
                <Container>

                    <Navbar bg="light" expand="md">
                        <Navbar.Brand as={Link} to="/">Bicocca4Students</Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ml-auto">
                                <Nav.Link as={Link} to="/">Home</Nav.Link>
                                {areaStudente}
                                {areaTim}
                            </Nav>
                            {loginButton}

                        </Navbar.Collapse>
                    </Navbar>

                    <Switch>
                        <Route path="/" exact>
                            <Home
                                apiAuthResponse={props.apiAuthResponse}
                                apiAuthName={props.apiAuthName}
                                apiAuthSurname={props.apiAuthSurname}
                                apiAuthGroups={props.apiAuthGroups}
                                stuMatricola={props.stuMatricola}
                                stuIndiRes={props.stuIndiRes}
                                stuIndiDom={props.stuIndiDom}
                            />
                        </Route>
                        <Route path="/ReqLab" exact>
                            <ReqLab
                                apiAuthResponse={props.apiAuthResponse}
                                apiAuthName={props.apiAuthName}
                                apiAuthSurname={props.apiAuthSurname}
                                apiAuthGroups={props.apiAuthGroups}
                            />
                        </Route>
                        <Route path="/PubLab" exact>
                            <PubLab
                                apiAuthResponse={props.apiAuthResponse}
                                apiAuthName={props.apiAuthName}
                                apiAuthSurname={props.apiAuthSurname}
                                apiAuthGroups={props.apiAuthGroups}
                            />
                        </Route>
                        <Route path="/PubLab/register/:handle"
                            render={(newProps) => <RegisterLab {...newProps}
                                title={`Pagina di registrazione ai Laboratori Virtuali`}
                                apiAuthResponse={props.apiAuthResponse}
                                apiAuthName={props.apiAuthName}
                                apiAuthSurname={props.apiAuthSurname}
                                apiAuthGroups={props.apiAuthGroups}
                            />} >
                        </Route>
                        <Route path="/timConsolle" exact>
                            <TimConsolle
                                apiAuthResponse={props.apiAuthResponse}
                                apiAuthName={props.apiAuthName}
                                apiAuthSurname={props.apiAuthSurname}
                                apiAuthGroups={props.apiAuthGroups}
                            />
                        </Route>
                        <Route path="/timInventory" exact>
                            <TimInventory
                                apiAuthResponse={props.apiAuthResponse}
                                apiAuthName={props.apiAuthName}
                                apiAuthSurname={props.apiAuthSurname}
                                apiAuthGroups={props.apiAuthGroups}
                            />
                        </Route>
                        <Route path="/voucherConsolle" exact>
                            <VoucherConsolle
                                apiAuthResponse={props.apiAuthResponse}
                                apiAuthName={props.apiAuthName}
                                apiAuthSurname={props.apiAuthSurname}
                                apiAuthGroups={props.apiAuthGroups}
                            />
                        </Route>
                        <Route path="/Sim4student" exact>
                            <Sim4student
                                apiAuthResponse={props.apiAuthResponse}
                                apiAuthName={props.apiAuthName}
                                apiAuthSurname={props.apiAuthSurname}
                                apiAuthGroups={props.apiAuthGroups}
                                stuMatricola={props.stuMatricola}
                                tipoCorsoCod={props.tipoCorsoCod}
                                stuCodFis={props.stuCodFis}
                                stuIndiRes={props.stuIndiRes}
                                stuIndiDom={props.stuIndiDom}
                            />
                        </Route>
                        <Route path="/ApplySimHome" exact>
                            <ApplySimHome
                                apiAuthResponse={props.apiAuthResponse}
                                apiAuthName={props.apiAuthName}
                                apiAuthSurname={props.apiAuthSurname}
                                apiAuthGroups={props.apiAuthGroups}
                                stuMatricola={props.stuMatricola}
                                stuIndiRes={props.stuIndiRes}
                                stuIndiDom={props.stuIndiDom}
                            />
                        </Route>
                        <Route path="/Voucherstudent" exact>
                            <Voucherstudent
                                apiAuthResponse={props.apiAuthResponse}
                                apiAuthName={props.apiAuthName}
                                apiAuthSurname={props.apiAuthSurname}
                                apiAuthGroups={props.apiAuthGroups}
                                stuMatricola={props.stuMatricola}
                                stuCodFis={props.stuCodFis}
                                stuIndiRes={props.stuIndiRes}
                                stuIndiDom={props.stuIndiDom}
                            />
                        </Route>
                        <Route path="/applyVoucher/id/:idVoucher/chk/:qrCode"
                            render={(newProps) => <ApplyVoucher {...newProps}
                                title={`Apply Voucher`}
                            />} >
                        </Route>
                        <Route path="/applySim/id/:idSim/chk/:qrCode"
                            render={(newProps) => <ApplySim {...newProps}
                                title={`Apply SIM`}
                            />} >
                        </Route>
                        <Route path="/Popsostudent" exact>
                            <Popsostudent
                                apiAuthResponse={props.apiAuthResponse}
                                apiAuthName={props.apiAuthName}
                                apiAuthSurname={props.apiAuthSurname}
                                apiAuthGroups={props.apiAuthGroups}
                                stuMatricola={props.stuMatricola}
                                stuIndiRes={props.stuIndiRes}
                                stuIndiDom={props.stuIndiDom}
                            />
                        </Route>
                        <Route path="/Trenitaliastudent" exact>
                            <Trenitaliastudent
                                apiAuthResponse={props.apiAuthResponse}
                                apiAuthName={props.apiAuthName}
                                apiAuthSurname={props.apiAuthSurname}
                                apiAuthGroups={props.apiAuthGroups}
                                stuMatricola={props.stuMatricola}
                                stuIndiRes={props.stuIndiRes}
                                stuIndiDom={props.stuIndiDom}
                            />
                        </Route>
                        <Route path="/PubLabDettPage/" exact component={PubLabDettPage} />
                        <Route render={function () {
                            return <p>Pagina non trovata</p>
                        }} />
                    </Switch>

                </Container>
            </Router>
        </React.Fragment>


    );
}




export default BootstrapNavbar;