import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';


function TrenitaliastudentPage(props) {
    var myRet;

    useEffect(() => {
    }, []);



    if (!props.apiAuthName)
        myRet = null;
    else {
        myRet = (
            <React.Fragment>
                <h2 className="title-page">Trenitalia Welfare Plus 10%</h2>
                <br />
                <p>                
                Nell’ambito del Programma Trenitalia for Business, Trenitalia ha il piacere di riservare agli Studenti
                iscritti all’Università degli Studi di Milano-Bicocca l’offerta Welfare PLUS 10%, 
                uno speciale sconto del 10% valido per l’acquisto di biglietti per viaggi privati degli Studenti 
                con un massimo di 6 accompagnatori.
                </p>
                <p>
                Lo sconto si applica sul prezzo Base e sulle offerte Economy e Super Economy, ai treni Frecciarossa, 
                Frecciargento e Frecciabianca per tutti gli acquisti effettuati fino al 31 dicembre 2025.
                </p>
                <p>
                Per usufruire dello sconto Welfare Plus 10% è necessario:
                </p>
                <ol>
                <li>Essere titolare di una CartaFRECCIA Trenitalia</li>
                <li>Associare la propria CartaFRECCIA al programma Trenitalia for Business</li>
                </ol>
                <p>
                Si ricorda che l’associazione della propria CartaFRECCIA al programma Trenitalia for Business è strettamente 
                riservata agli Studenti regolarmente iscritti all’Università e ha validità di 12 mesi.
                </p>
                <p>
                Per iscriverti al programma CartaFRECCIA e/o associare la CartaFRECCIA segui le istruzioni contenute 
                nella guida allegata, utilizzando il link sottostante.
                </p>
                <p>
                Per maggiori informazioni entra nella tua area riservata e consulta la brochure. 
                </p>
                
                <a href="./trenitalia/trenitalia_welfare_plus_2025.pdf">Brochure Trenitalia Welfare Plus</a>

                <br /><br /><br />

                <Button href={process.env.REACT_APP_URL_TRENITALIASTUDENT}>
                    Area riservata per accedere allo sconto</Button>{' '}


            </React.Fragment >
        );

    }

    return myRet;
}

export default TrenitaliastudentPage;
