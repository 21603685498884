import React from 'react';
import Can from "../components/Can";

const hp = {
    //height: "300px"
}

function Home(props) {
    var myHome;

    if (!props.apiAuthResponse) {
        myHome = (
            <React.Fragment>
                <div style={hp}>
                    <h2 className="welcome">Bicocca4Students</h2>
                    <p>Per accedere al portale occorre autenticarsi</p>
                </div>
            </React.Fragment>
        )
    } else {
        var itemTim = <Can
            role={props.apiAuthGroups}
            perform="tim4student-page:visit"
            yes={() => (
                <li>Iniziativa SIM4STUDENTS - Gestione SIM dati e Router - <a href="https://www.unimib.it/node/20401">Informazioni aggiuntive</a> - <a href="https://www.unimib.it/servizi/service-desk/telefonia-e-servizi-voce-voiceunimib/telefonia-mobile/sim4student-faq">FAQ</a></li>
            )}
            no={() => null}
        />

        var itemTimReq = <Can
            role={props.apiAuthGroups}
            perform="tim4student-req-page:visit"
            yes={() => (
                <li>Iniziativa SIM4STUDENTS - Servizio di richiesta SIM dati e Router</li>
            )}
            no={() => null}
        />

        var itemTimConsolle = <Can
            role={props.apiAuthGroups}
            perform="timConsolle-page:visit"
            yes={() => (
                <li>Consolle Customer Care TIM</li>
            )}
            no={() => null}
        />

        var itemVoucherConsolle = <Can
            role={props.apiAuthGroups}
            perform="voucherConsolle-page:visit"
            yes={() => (
                <li>Consolle Voucher</li>
            )}
            no={() => null}
        />

        var itemVoucher = <Can
            role={props.apiAuthGroups}
            perform="voucherstudent-page:visit"
            yes={() => (
                <li>Richiesta Voucher per acquisto PC, notebook o Tablet - <a href="https://www.unimib.it/node/20400">Informazioni aggiuntive</a> - <a href="https://www.unimib.it/node/20453">FAQ</a></li>
            )}
            no={() => null}
        />

        var itemPopso = <Can
            role={props.apiAuthGroups}
            perform="popsostudent-page:visit"
            yes={() => (
                <li>Conferma ricezione Badge Ateneo+</li>
            )}
            no={() => null}
        />

        var itemTrenitaliastudent = <Can
            role={props.apiAuthGroups}
            perform="trenitaliastudent-page:visit"
            yes={() => (
                <li><a href={"/Trenitaliastudent"}>Trenitalia Welfare Plus 10%</a></li>
            )}
            no={() => null}
        />


        if (!props.apiAuthName)
            myHome = null;
        else {
            var myStuMatricola;
            if (!props.stuMatricola)
                myStuMatricola = null;
            else
                myStuMatricola = (
                    <p><b>Matricola  {props.stuMatricola}</b></p>
                );

            myHome = (
                <React.Fragment>
                    <div style={hp}>
                        <h1 className="welcome">{props.apiAuthName} {props.apiAuthSurname}</h1>
                        {myStuMatricola}
                        <br />
                        <h3 className="servizi">Servizi disponibili</h3>
                        {itemTim}
                        {itemTimReq}
                        {itemVoucher}
                        {itemPopso}
                        {itemTrenitaliastudent}
                        {itemTimConsolle}
                        {itemVoucherConsolle}
                    </div>
                </React.Fragment>
            )
        }
    }

    return myHome;
}

export default Home;