import React from 'react';
import { padStart } from "lodash";

function VoucherBarcode(props) {
  const myValue = 'X' + padStart(String(props.value), 7, '0');
/*
  const { inputRef } = useBarcode({
    value: myValue,
    options: {
      displayValue: true,
      //background: '#ffc0cb',
    }
  });
  */
  
  return ;
};

export default VoucherBarcode;